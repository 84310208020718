import React from "react";
import "./Gifts5.css";

const Gifts5 = () => {
  return (
    <>
      <div className="gifts5">
        <h3>Gifts for Pets. Great gifts for pets this year!</h3>
        <div>
          <img src="https://via.placeholder.com/150" alt="gift1" />
          <p>Gift 1</p>
          <img src="https://via.placeholder.com/150" alt="gift2" />
          <p>Gift 2</p>
        </div>
      </div>
    </>
  );
};

export default Gifts5;