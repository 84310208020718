import React from "react";
import "./Gifts2.css";

const Gifts2 = () => {
  return (
    <>
      <div className="gifts2">
        <h3>Teen Gift Ideas. Great gifts for teens!</h3>
        <div>
          <img src="https://via.placeholder.com/150" alt="gift1" />
          <p>Gift 1</p>
          <img src="https://via.placeholder.com/150" alt="gift2" />
          <p>Gift 2</p>
        </div>
      </div>
    </>
  );
};

export default Gifts2;
