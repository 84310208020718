import React from "react";
import "./Gifts3.css";

const Gifts3 = () => {
  return (
    <>
      <div className="gifts3">
      <h3>Gifts for Kids, Best gift for kids this year!</h3>
        <div>
          <img src="https://via.placeholder.com/150" alt="gift1" />
          <p>Gift 1</p>
          <img src="https://via.placeholder.com/150" alt="gift2" />
          <p>Gift 2</p>
        </div>
        
      </div>
    </>
  );
};

export default Gifts3;
